<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search
              <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.name"
                    type="text"
                    label="Name"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="searchParam.equipmentFilter"
                    :items="equipment"
                    item-text="name"
                    item-value="id"
                    label="Equipment Type"
                    clearable
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.dotNumber"
                    type="text"
                    label="DOT Number"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.mcNumber"
                    type="text"
                    label="MC Number"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.loadId"
                    type="text"
                    label="Load Number"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.originCity"
                    type="text"
                    label="Origin City"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.originState"
                    type="text"
                    label="Origin State"
                    hint="Short state name (Ex. Texas - TX)"
                    persistent-hint
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.destinationCity"
                    type="text"
                    label="Destination City"
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="searchParam.destinationState"
                    type="text"
                    label="Destination State"
                    hint="Short state name (Ex. Texas - TX)"
                    persistent-hint
                    @keydown.enter="searchAllQuotes()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllQuotes()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container id="tblQuotes" fluid class="pt-0 data-container">
        <v-data-table
          id="tblData"
          :headers="headers"
          :items="quotes"
          class="elevation-2 data-table"
          calculate-widths
          hide-default-footer
          :loading="loadingIcon"
          fixed-header
          :items-per-page="pageSize"
        >
          <template v-slot:item.load="{ item }">
            <v-btn
              text
              small
              target="_blank"
              :to="{
                name: 'Load Detail',
                params: { id: item.load.id },
              }"
            >
              {{ item.load.id }}
            </v-btn>
          </template>
          <template v-slot:item.shipper="{ item }">
            <span v-if="item.shipper">{{
              formatLocation(
                item.shipper.city,
                item.shipper.state_province_region
              )
            }}</span>
          </template>
          <template v-slot:item.consignee="{ item }">
            <span v-if="item.consignee">{{
              formatLocation(
                item.consignee.city,
                item.consignee.state_province_region
              )
            }}</span>
          </template>
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="item.carrierCompany"
              :to="{
                name: 'Carrier Detail',
                params: { id: item.carrierCompany },
              }"
              class="name-link"
              target="_blank"
            >
              <span>{{ item.name }}</span>
            </router-link>
            <span v-else
              >{{ item.name }}
              <v-icon x-small color="red">mdi-bus-alert</v-icon></span
            >
          </template>
          <template v-slot:footer>
            <v-page
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
export default {
  name: "AllQuotes",
  data() {
    return {
      searchParam: {
        name: null,
        equipmentFilter: null,
        dotNumber: null,
        mcNumber: null,
        loadId: null,
        carrierId: null,
        originCity: null,
        originState: null,
        destinationCity: null,
        destinationState: null,
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Load #", value: "load", align: "center" },
        { text: "Origin", value: "shipper", align: "center" },
        { text: "Destination", value: "consignee", align: "center" },
        {
          text: "Equipment Type",
          value: "equipmentType",
          align: "start",
        },
        { text: "DOT #", value: "dotNumber", align: "center" },
        { text: "MC #", value: "mcNumber", align: "center" },
        { text: "Phone", value: "phone", align: "center" },
        { text: "Email", value: "email", align: "start" },
        { text: "Date", value: "createdAt", align: "center" },
        { text: "Bid Amount", value: "carrierBid", align: "right" },
      ],
      quotes: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("carrierMatching", {
      quoteList: "quoteList",
    }),
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
      equipment: "equipmentTypes",
    }),
  },
  watch: {
    quoteList() {
      this.totalRecord = this.quoteList.count
      this.quotes = this.quoteList.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.quotes = []
    this.getQuotes()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getQuotes() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.$store.dispatch("carrierMatching/GET_QUOTES", this.queryParams)
    },
    resetSearch() {
      this.searchParam.name = null
      this.searchParam.equipmentFilter = null
      this.searchParam.dotNumber = null
      this.searchParam.mcNumber = null
      this.searchParam.loadId = null
      this.searchParam.carrierId = null
      this.quotes = []
      this.queryParams = {}
      this.getQuotes()
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 20
      this.queryParams.offset = 0
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.equipmentFilter) {
        this.queryParams.equipment_type = this.searchParam.equipmentFilter
      }
      if (this.searchParam.dotNumber) {
        this.queryParams.dot_number__icontains = this.searchParam.dotNumber
      }
      if (this.searchParam.mcNumber) {
        this.queryParams.mc_number__icontains = this.searchParam.mcNumber
      }
      if (this.searchParam.loadId) {
        this.queryParams.load_id = this.searchParam.loadId
      }
      if (this.searchParam.carrierId) {
        this.queryParams.carrier_company = this.searchParam.carrierId
      }
      if (this.searchParam.originCity) {
        this.queryParams.load__shipper__city__icontains =
          this.searchParam.originCity
      }
      if (this.searchParam.originState) {
        this.queryParams.load__shipper__state_province_region__icontains =
          this.searchParam.originState
      }
      if (this.searchParam.destinationCity) {
        this.queryParams.load__consignee__city__icontains =
          this.searchParam.destinationCity
      }
      if (this.searchParam.destinationState) {
        this.queryParams.load__consignee__state_province_region__icontains =
          this.searchParam.destinationState
      }
    },
    searchAllQuotes() {
      this.locationList = []
      this.createQueryString()
      this.$store.dispatch("carrierMatching/GET_QUOTES", this.queryParams)
    },
    selectedCarrier(value) {
      if (value.carrier) {
        if (value.flag === 1) {
          this.searchParam.carrierId = value.carrier.id
          this.searchAllQuotes()
        } else {
          this.searchParam.carrierId = value.carrier.id
        }
      }
    },
    readDataFromAPI(value) {
      if (this.quotes.length > 0) {
        this.loading = true
        this.page = value.pageNumber
        this.pageSize = value.pageSize
        let pageNumber = this.pageSize * this.page - this.pageSize
        if (pageNumber < 0) {
          pageNumber = 0
        }
        this.queryParams.offset = pageNumber
        this.queryParams.limit = this.pageSize
        this.$store.dispatch("carrierMatching/GET_QUOTES", this.queryParams)
      }
    },
  },
}
</script>

<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
